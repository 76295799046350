import React from 'react';
import SkyLight from 'react-skylight';
import Constants from "../constants";
import {inclusiveIntList} from "../utils";

class OptionsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: Constants.heightPV.getValue(),
      width: Constants.widthPV.getValue(),
      size: Constants.sizePV.getValue(),
      squareBoard: Constants.squareBoardPV.getValue(),
      eliminateRedsFirst: Constants.defaultGameOptions.eliminateRedsFirst,
      sameSolutionAsPreviousGame: Constants.defaultGameOptions.sameSolutionAsPreviousGame,
      difficulty: Constants.difficultyPV.getValue(),
      includeSelfInSeenBluesCount: Constants.includeSelfInSeenBluesCountPV.getValue(),
    }
  }

  setSelectOptionFn(pv) {
    return (event) => {
      const optionString = event.target.value;
      // console.log(`saw ${optionString}`)
      // @ts-ignore
      pv.setValue(optionString);
      this.setState({[pv.name]: optionString});
    }
  }

  setDifficulty = (event) => {
    const value = event.target.value;
    Constants.difficultyPV.setValue(value);
    this.setState({difficulty: value});
  }

  setSquare = (event) => {
    const checked = event.target.checked;
    Constants.squareBoardPV.setValue(checked);
    this.setState({squareBoard: checked});
  }

  setEliminateRedsFirst = (event) => {
    const checked = event.target.checked;
    this.setState({eliminateRedsFirst: checked});
  }

  setSameSolutionAsPreviousGame = (event) => {
    const checked = event.target.checked;
    this.setState({sameSolutionAsPreviousGame: checked});
  }

  setIncludeSelfInSeenBluesCount = (event) => {
    const checked = event.target.checked;
    Constants.includeSelfInSeenBluesCountPV.setValue(checked);
    this.setState({includeSelfInSeenBluesCount: checked});
  }

  getGameOptions() {
    const size =
      Constants.squareBoardPV.getValue() ? {
          height: Constants.sizePV.getValue(),
          width: Constants.sizePV.getValue(),
        } :
        {
          height: Constants.heightPV.getValue(),
          width: Constants.widthPV.getValue(),
        };
    console.log(size)
    const {eliminateRedsFirst, sameSolutionAsPreviousGame, difficulty} = this.state;
    const {includeSelfInSeenBluesCount} = this.state;
    const displayOptions = {
      includeSelfInSeenBluesCount
    };

    return {
      ...Constants.defaultGameOptions,
      size,
      eliminateRedsFirst,
      sameSolutionAsPreviousGame,
      difficulty,
      displayOptions,
    };
  }

  render() {

    var myBigGreenDialog = {
      backgroundColor: '#77C76E',
      color: '#ffffff',
      width: '70%',
      height: '80%',
      marginTop: '-35%',
      marginLeft: '-35%',
    };

    return (<>
        <button className={'button game-button__options'} onClick={() => this.customDialog.show()}>Options</button>

        <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked ref={ref => this.customDialog = ref}
                  title="Kuromasu Options">

          <label>
            <h2>Difficulty</h2>
            <select name="difficulty" value={this.state.difficulty} onChange={this.setDifficulty}>
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select>
            <br/>
            In hard difficulty, the hints provided are minimal and every one is needed to find the solution. In the
            others, some superfluous information is provided, making it a little easier to solve.
          </label>

          <br/>
          <br/>

          <label>
            <input type="checkbox" checked={this.state.squareBoard} onChange={this.setSquare}/>
            Square Board?
          </label>

          <div className={'stack'}>
            {this.state.squareBoard ?
              <label>
                Size:&nbsp;
                <select name='size'
                        value={this.state.size}
                        onChange={this.setSelectOptionFn(Constants.sizePV)}>
                  {inclusiveIntList(Constants.minSelectableGridSize, Constants.maxSelectableGridSize).map(s =>
                    <option value={s.toString()} key={s.toString()}>{s.toString()}</option>
                  )}
                </select>

              </label> :
              <>
                <label>
                  Height:&nbsp;
                  <select name='height'
                          value={this.state.height}
                          onChange={this.setSelectOptionFn(Constants.heightPV)}>
                    {inclusiveIntList(Constants.minSelectableGridSize, Constants.maxSelectableGridSize).map(s =>
                      <option value={s.toString()} key={s.toString()}>{s.toString()}</option>
                    )}
                  </select>
                </label>
                <label>
                  Width:&nbsp;
                  <select name='width'
                          value={this.state.width}
                          onChange={this.setSelectOptionFn(Constants.widthPV)}>
                    {inclusiveIntList(Constants.minSelectableGridSize, Constants.maxSelectableGridSize).map(s =>
                      <option value={s.toString()} key={s.toString()}>{s.toString()}</option>
                    )}
                  </select>
                </label>
              </>
            }
          </div>

          <br/>

          <h2>Puzzle Generation Rules</h2>
          <label>
            <input type="checkbox" checked={this.state.eliminateRedsFirst}
                   onChange={this.setEliminateRedsFirst}/>
            Minimize reds
          </label>

          <br/>

          <label>
            <input type="checkbox" checked={this.state.sameSolutionAsPreviousGame}
                   onChange={this.setSameSolutionAsPreviousGame}/>
            Same puzzle as previous, but with different hints.
          </label>

          <h2>Display Options</h2>

          <label>
            <input type="checkbox" checked={this.state.includeSelfInSeenBluesCount}
                   onChange={this.setIncludeSelfInSeenBluesCount}/>
            Include self in displayed count of seen blues.
          </label>

        </SkyLight>
      </>
    )
  }
}

export default OptionsPopup;
