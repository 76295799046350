import {GameOptions, InitialBoardState} from "../types";
import GridLocation from "./gridLocation";
import {allGridLocations} from "../utils";
import {ConstraintReducer} from "./constraintReducer";
import {getRandomSolvedBoard} from "./boardGeneration";
import Constants from "../constants";

var Logic = require('logic-solver');

let randomBoard: InitialBoardState;

export function findNewBoard(options: GameOptions): InitialBoardState {
    const t0 = performance.now();
    const solvedBoard = options.sameSolutionAsPreviousGame && randomBoard ?
        randomBoard :
        getRandomSolvedBoard(options);
    randomBoard = solvedBoard;

    // return randomBoard;

    const cr = new ConstraintReducer(solvedBoard);

    // if (options.eliminateRedsFirst) cr.tryEliminateReds();
    const returnedConstraintProportion = Constants.constraintReturnsForDifficulty[options.difficulty];
    const result = cr.minimize(options.eliminateRedsFirst, returnedConstraintProportion);

    const t1 = performance.now();
    console.log(`findNewBoard Time: ${Math.floor(t1 - t0)}ms`);

    return result;

    // return Constants.simpleFiveByFivePuzzle;
}

interface SolvedState {
    blues: GridLocation[],
    reds: GridLocation[],
}

export function solve(initialState: InitialBoardState): SolvedState | 'unsolvable' | 'nonunique' {
    // console.log(`Initializing solver.`);
    // const startTime = performance.now();
    let solver = new Logic.Solver();

    // Needed so we don't let unconstrained squares creep in when they don't happen to turn up in our formulas.
    // Here we're just saying that at least one of the squares is blue, which is certainly true, but it forces the sat
    // solver to recognize the existence of all of our variables, which we might not do in the clauses generated below.
    const anythingClause = Logic.or(allGridLocations(initialState.size).map(loc => loc.toString()));
    solver.require(anythingClause);

    initialState.constraints.forEach(constraint => {
        // Neighbourhood blues seen clauses where required.
        if (constraint.visibleNeighbours) {
            const formula = Logic.equalBits(constraint.loc.neighbourhoodSumClause(initialState.size),
                Logic.constantBits(constraint.visibleNeighbours));
            solver.require(formula);
        }

        // Color clause where required;
        if (constraint.color) {
            let term = constraint.loc.toString();
            switch (constraint.color) {
                case "blue":
                    solver.require(term);
                    break;
                case "red":
                    solver.require(Logic.not(term));
            }
        }

    });

    // console.log(`Running solver.`);
    const soln = solver.solve();
    if (!soln) return 'unsolvable';
    // Forbid the discovered solution.
    solver.forbid(soln.getFormula());
    // If we can still solve it, we don't have a unique solution.
    if (solver.solve()) return "nonunique";

    // Process out the reds and blues from our result.
    const reds = []
    const blues = [];
    const solnMap = soln.getMap();
    for (let key in solnMap) {
        const value = solnMap[key];
        const loc = GridLocation.fromString(key);
        // console.log(`${key} ${value}`)
        if (value) {
            blues.push(loc);
        } else {
            reds.push(loc);
        }
    }

    // const endTime = performance.now();
    // console.log(`Solver Time: ${Math.floor(endTime - startTime)}ms`);

    return {blues, reds};
}

