import React, {Component, CSSProperties} from 'react';
import Game from "../logic/game";
import '../css/gameBoard.css';
import GameTile from "./gameTile";
import GridLocation from "../logic/gridLocation";
import {DisplayOptions} from "../types";


interface GameBoardProps {
    game: Game,
    displayOptions: DisplayOptions,
}

interface GameBoardState {
    width: number,
    height: number,
}

class GameBoard extends Component<GameBoardProps, GameBoardState> {
    constructor(props: GameBoardProps) {
        super(props);
        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
        }
    }

    tileClickFn = (loc: GridLocation) => {
        console.log(`Click seen to ${loc.row}/${loc.col}`);
        this.props.game.toggleCellColor(loc);
        this.forceUpdate();
    }

    render() {
        const game = this.props.game;
        const style = {
            '--rows': this.props.game.size.height,
            '--cols': this.props.game.size.width,
            '--cell-sizepx': this.getMaxCellSize(),
        } as CSSProperties;

        return (
            <div className='gameboard__outer'>
                <div className={this.cssClasses()} style={style}>
                    {game.tileStates.flat().map((tile) =>
                        <GameTile displayOptions={this.props.displayOptions}
                                  tileState={tile}
                                  tileClickFn={this.tileClickFn}
                                  key={tile.location.toString()}
                        />)}
                </div>
            </div>
        );
    }


    updateDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    private getMaxCellSize() {
        const game = this.props.game;
        const availableHeight = this.state.height - 40 - 80;
        const availableWidth = this.state.width - 40;
        const maxCellHeight = availableHeight / game.size.height;
        const maxCellWidth = availableWidth / game.size.width;
        return Math.floor(Math.min(maxCellHeight, maxCellWidth));
    }

    private cssClasses() {
        const game = this.props.game;
        const classes = [
            'gameboard',
        ];
        if (game.isCompleted()) classes.push('gameboard--complete');
        else classes.push('gameboard--incomplete');

        return classes.join(' ');
    }
}

export default GameBoard;