import {Constraint, GridSize, InitialBoardState} from "../types";
import GridLocation from "./gridLocation";
import {shuffle} from "../utils";
import {solve} from "./search";

export class ConstraintReducer {
    private size: GridSize;
    private redLocs: GridLocation[];
    private blueLocs: GridLocation[];
    private constrainedLocs: Map<GridLocation, Constraint>;
    private eliminatedConstraints: Constraint[] = [];

    constructor(public readonly completelyConstrainedBoardState: InitialBoardState) {
        this.size = completelyConstrainedBoardState.size;
        this.constrainedLocs = new Map<GridLocation, Constraint>(
            this.completelyConstrainedBoardState.constraints.map(c => [c.loc, c])
        );
        this.redLocs = completelyConstrainedBoardState.constraints.filter(c => c.color === "red").map(c => c.loc);
        this.blueLocs = completelyConstrainedBoardState.constraints.filter(c => c.color === "blue").map(c => c.loc);
        this.tryEliminateConstrainedLoc = this.tryEliminateConstrainedLoc.bind(this);
    }

    public minimize(eliminateRedsFirst: boolean, returnedConstraintProportion: number = 0.0): InitialBoardState {
        if (eliminateRedsFirst) this.tryEliminateReds();

        const initialConstraints = shuffle(Array.from(this.constrainedLocs.keys()));
        shuffle(initialConstraints).forEach(this.tryEliminateConstrainedLoc);

        // Get some new constraints, but always leaving out at least 2, which gives a shitty puzzle, but at least it
        // will make us never deliver a solved puzzle.
        const necessaryBlanks = 2;
        const returnedConstraintCount = Math.min(
            Math.ceil(returnedConstraintProportion * this.eliminatedConstraints.length),
            this.eliminatedConstraints.length - necessaryBlanks);
        const returnableConstraints = eliminateRedsFirst ?
            this.eliminatedConstraints.filter(c => c.color === "blue") :
            this.eliminatedConstraints;
        const returnedConstraints = shuffle(returnableConstraints).slice(0, returnedConstraintCount);
        console.log(`Returning ${returnedConstraints.length} constraints in deference to difficulty level.`)
        const constraints = Array.from(this.constrainedLocs.values()).concat(returnedConstraints);

        return {
            size: this.size,
            constraints: constraints,
        };
    }

    private tryEliminateReds() {
        shuffle(this.redLocs).forEach(loc => {
            this.tryEliminateConstrainedLoc(loc);
        });
    }

    private tryEliminateConstrainedLoc(loc: GridLocation) {
        const instance: InitialBoardState = {
            size: this.size,
            constraints: Array.from(this.constrainedLocs.values()).filter(c => c.loc !== loc),
        };
        const result = solve(instance);
        if (result === "nonunique" || result === 'unsolvable') return;

        // If there was a unique solution, it turns out we don't need this constraint and so we ditch it.
        this.eliminatedConstraints.push(this.constrainedLocs.get(loc)!);
        this.constrainedLocs.delete(loc);
    }
}