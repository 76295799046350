import React, {Component} from 'react';
import OptionsPanel from "./components/optionsPanel";
import './css/App.css';
import Game from "./logic/game";
import GameBoard from "./components/gameBoard";
import {DisplayOptions, GameOptions} from "./types";
import {findNewBoard} from "./logic/search";

interface AppProps {
}

interface AppState {
    game?: Game,
    displayOptions?: DisplayOptions,
}

class App extends Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {}
    }

    newGame = (options: GameOptions) => {
        console.log('Starting new game.');
        // console.log(`options.displayOptions.includeSelfInSeenBluesCount ${options.displayOptions.includeSelfInSeenBluesCount}`)
        this.setState({
            game: new Game(findNewBoard(options)),
            displayOptions: options.displayOptions,
        });
    }

    undo = () => {
        if (this.state.game) {
            console.log('Trying to undo previous move.');
            this.state.game.undo();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div className="app">
                <OptionsPanel
                    startFn={this.newGame}
                    undoFn={this.undo}
                    gameOn={!!this.state.game}
                />
                {this.state.game && this.state.displayOptions ?
                    <GameBoard
                        game={this.state.game}
                        displayOptions={this.state.displayOptions}
                    /> : <></>}
            </div>
        );
    }
}

export default App;
