import {Constraint, GameOptions, GridSize, InitialBoardState, TileColor} from "../types";
import {allGridLocations, countAdjacentBlues} from "../utils";
import GridLocation from "./gridLocation";
import Constants from "../constants";

function bluesAreDisconnected(redLocs: Set<string>, size: GridSize) {
    // Check if everything is one connected component or not.
    const allBlues = allGridLocations(size).map(loc => loc.toString()).filter(loc => !redLocs.has(loc));
    const needsLook = new Array<string>();
    // Take one element as the seed.
    needsLook.push(allBlues[0]);

    const unseenBlues = new Set(allBlues);

    // console.log(`allBlues.length ${allBlues.length}`)
    while (needsLook.length > 0) {
        const curloc = needsLook.shift()!;
        if (unseenBlues.has(curloc)) {
            unseenBlues.delete(curloc);
            const neighbours = GridLocation.fromString(curloc).immediateNeighbours(size).map(loc => loc.toString());
            needsLook.push(...neighbours);
        }
    }

    return unseenBlues.size > 0;
}

export function getRandomSolvedBoard(options: GameOptions): InitialBoardState {
    const size = options.size;
    const constraints = [];

    // Set the number of reds in the puzzle.
    const redProportion = Constants.minRedProportion + Math.random() * (Constants.maxRedProportion - Constants.minRedProportion);
    const redCount = Math.floor(redProportion * size.height * size.width);

    // Pick the red locations.
    const redLocs = new Set<string>();
    const redLocsForbidden = new Set<string>();
    while (redLocs.size < redCount) {
        let randomSpot = GridLocation.random(size);
        while (redLocsForbidden.has(randomSpot.toString())) randomSpot = GridLocation.random(size);

        const locStr = randomSpot.toString();
        redLocs.add(locStr);
        if (bluesAreDisconnected(redLocs, size)) {
            redLocs.delete(locStr);
            // console.log(`Rejecting loc ${locStr}`)
            redLocsForbidden.add(locStr);
        }

        if (redLocs.size + redLocsForbidden.size === size.height * size.width) break;
    }

    // Construct the appropriate Constraint entries.
    let finalRedCount = 0;

    function getColor(loc: GridLocation): TileColor {
        if (!loc.onBoard(size)) return 'off board';
        return redLocs.has(loc.toString()) ? 'red' : 'blue';
    }

    for (let i = 0; i < size.height; i += 1) {
        for (let j = 0; j < size.width; j += 1) {
            const loc = new GridLocation(i, j);
            // You're red if you got selected or if all your neighbours are red. No blue islands.
            const isRed = redLocs.has(loc.toString()) ||
                loc.immediateNeighbours(size).every(nloc => redLocs.has(nloc.toString()));
            finalRedCount += isRed ? 1 : 0;
            const constraint = {
                loc: loc,
                color: isRed ? 'red' : 'blue',
                visibleNeighbours: isRed ? undefined : countAdjacentBlues(loc, getColor).blueCount,
            } as Constraint;
            constraints.push(constraint);
        }
    }

    console.log(`getRandomSolvedBoard redcounts ${redLocs.size}/${finalRedCount} rejectedRedLocs ${redLocsForbidden.size}`);

    return {
        size: size,
        constraints: constraints,
    }
}