import GridLocation from "./logic/gridLocation";

export type DifficultyLevel = 'hard' | 'medium' | 'easy';

export interface DisplayOptions {
    includeSelfInSeenBluesCount: boolean,
}

export interface GameOptions {
    size: GridSize,
    eliminateRedsFirst: boolean,
    sameSolutionAsPreviousGame: boolean,
    difficulty: DifficultyLevel,
    displayOptions: DisplayOptions,
}

export interface GridSize {
    height: number,
    width: number
}

export type FixedTileColor = 'red' | 'blue';
export type TileColor = 'red' | 'blue' | 'unknown' | 'off board';

export interface Constraint {
    loc: GridLocation,
    color: FixedTileColor,
    visibleNeighbours?: number,
}

export interface InitialBoardState {
    size: GridSize,
    constraints: Constraint[],
}

export enum SatisfactionState {
    Satisfied = 11,
    TooFew,
    TooMany,
    RightNumberButUnterminated,
}

export interface ConstraintState {
    satisfactionState: SatisfactionState,
}

export interface TileState {
    location: GridLocation,
    currentColor: TileColor,
    constraintState?: ConstraintState,
    requiredBlueNeighbours?: number,

    fixedColor: boolean,
    debugString?: string,
}

export interface BlueCountResult {
    // How many blues are in the line of sight of our queried location.
    blueCount: number,
    // Whether all directions terminate correctly, at the edge of the board on on a red, instead of in an unset tile.
    allProperlyTerminated: boolean,
}