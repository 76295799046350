import GridLocation from "./logic/gridLocation";
import {BlueCountResult, GridSize, TileColor} from "./types";

export function countAdjacentBlues(location: GridLocation, getColor: (loc: GridLocation) => TileColor): BlueCountResult {
    let allProperlyTerminated = true;
    let blueCount = 0;
    let dirs: GridLocation[] = [
        new GridLocation(1, 0),
        new GridLocation(-1, 0),
        new GridLocation(0, 1),
        new GridLocation(0, -1),];

    for (const dir of dirs) {
        let curloc: GridLocation = location.copy();
        while (true) {
            curloc.row += dir.row;
            curloc.col += dir.col;
            const tileColor = getColor(curloc);
            if (tileColor === 'unknown') {
                allProperlyTerminated = false;
                break;
            } else if (tileColor === 'red' || tileColor === 'off board') {
                break;
            } else if (tileColor === "blue") {
                blueCount += 1;
            }
        }
    }
    return {allProperlyTerminated, blueCount,}
}

export function allSublistsFromStart<T>(list: T[]) {
    return list.map((value, index) => list.slice(0, index + 1));
}

export function shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export function allGridLocations(size: GridSize) {
    const ret = [];
    for (let i = 0; i < size.height; i += 1) {
        for (let j = 0; j < size.width; j += 1) {
            ret.push(new GridLocation(i, j));
        }
    }
    return ret;
}

export function inclusiveIntList(start: number, finish: number): number[] {
    const ret = [];
    for (let i = start; i <= finish; i += 1) {
        ret.push(i);
    }
    return ret;
}