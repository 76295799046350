import React, {Component} from 'react';
import '../css/gameTile.css';
import {DisplayOptions, SatisfactionState, TileState} from "../types";
import GridLocation from "../logic/gridLocation";

interface GameTileProps {
    displayOptions: DisplayOptions,
    tileState: TileState,
    tileClickFn: (loc: GridLocation) => void,
}

interface GameTileState {
}

class GameTile extends Component<GameTileProps, GameTileState> {
    clickFn = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        this.props.tileClickFn(this.props.tileState.location);
    }

    displayedText = () => {
        const ts = this.props.tileState;
        if (!ts.constraintState || !ts.requiredBlueNeighbours) return 'x';

        return ts.requiredBlueNeighbours + (this.props.displayOptions.includeSelfInSeenBluesCount ? 1 : 0);
    }

    render() {
        const ts = this.props.tileState;
        return (
            <div className={this.cssClasses()} key={ts.location.toString()}>
                <div className={'gametile__number'} onClick={this.clickFn}>
                    {this.displayedText()}
                </div>

            </div>
        );
    }

    private cssClasses() {
        let ts = this.props.tileState;
        let ret = [
            'gametile',
            `gametile--color-${ts.currentColor}`
        ];
        if (ts.fixedColor) ret.push('gametile--fixed-color');
        else ret.push('gametile--variable-color');
        if (ts.constraintState) {
            const statename = SatisfactionState[ts.constraintState?.satisfactionState];
            ret.push(`gametile--satisfactionstate__${statename}`);
        }

        return ret.join(' ');
    }
}

export default GameTile;