import React, {ChangeEvent, Component} from 'react';
import {DifficultyLevel, GameOptions} from "../types";
import "../css/optionsPanel.css";
import RulesPopup from "./rulesPopup";
import OptionsPopup from "./optionsPopup";

type SelectOption = 'difficulty' | 'size' | 'height' | 'width';

interface OptionsPanelProps {
    startFn: (options: GameOptions) => void,
    undoFn: () => void,
    gameOn: boolean,
}

interface OptionsPanelState {
}


class OptionsPanel extends Component<OptionsPanelProps, OptionsPanelState> {
    optionsPopup: React.RefObject<OptionsPopup>;

    constructor(props: OptionsPanelProps) {
        super(props);
        this.state = {};
        this.optionsPopup = React.createRef();
    }

    setSelectOptionFn(optionName: SelectOption) {
        return (event: ChangeEvent<HTMLSelectElement>) => {
            const optionString = event.target.value;
            // console.log(`saw ${optionString}`)
            // @ts-ignore
            this.setState({[optionName]: optionString});
        }
    }

    setDifficulty = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value as DifficultyLevel;
        this.setState({difficulty: value});
    }

    setSquare = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        this.setState({squareBoard: checked});
    }

    setEliminateRedsFirst = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        this.setState({eliminateRedsFirst: checked});
    }

    setSameSolutionAsPreviousGame = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        this.setState({sameSolutionAsPreviousGame: checked});
    }

    render() {
        return (
            <div className="header-panel">
                <button
                    className={'button game-button__start'}
                    onClick={(element) => {
                        const gameOptions = this.gameOptions();
                        if (gameOptions) {
                            this.props.startFn(gameOptions);
                        }
                    }}>
                    New Game
                </button>

                &nbsp;
                <OptionsPopup ref={this.optionsPopup}/>

                &nbsp;
                <RulesPopup/>

                &nbsp;
                {this.props.gameOn ?
                    <button
                        className={'button game-button__undo'}
                        onClick={(element) => {
                            this.props.undoFn();
                        }}>
                        Undo
                    </button>
                    : <></>}

            </div>
        );
    }

    private gameOptions() {
        return this.optionsPopup.current?.getGameOptions() as GameOptions;
    }
}

export default OptionsPanel;