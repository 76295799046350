import {GridSize} from "../types";
import {Directions} from "../constants";
import {allSublistsFromStart} from "../utils";

var Logic = require('logic-solver');

class GridLocation {
    private static boardLocMatcher = new RegExp(`^([0-9]+),([0-9]+)$`);

    constructor(public row: number, public col: number) {
    }

    public static fromString(str: string): GridLocation {
        let test = GridLocation.boardLocMatcher.exec(str);
        if (test) {
            return new GridLocation(+test[1], +test[2]);
        }
        throw new Error(`Unable to parse GridLocation ${str}`);
    }

    static random(size: GridSize): GridLocation {
        const row = Math.floor(size.height * Math.random());
        const col = Math.floor(size.width * Math.random());
        return new GridLocation(row, col);
    }

    public copy() {
        return new GridLocation(this.row, this.col);
    }

    public withMove(direction: GridLocation): GridLocation {
        return new GridLocation(this.row + direction.row, this.col + direction.col);
    }

    public neighbourhoodSumClause(size: GridSize) {
        const terms = Directions.allDirections
            .map(direction => this.neighboursToEdge(direction, size).map(n => n.toString()))
            .map(neighbourList => allSublistsFromStart(neighbourList).map(list => Logic.and(list)));
        return Logic.sum(terms);
    }

    public toString(): string {
        return `${this.row},${this.col}`;
    }

    public immediateNeighbours(size: GridSize): GridLocation[] {
        return Directions.allDirections.map(dir => this.withMove(dir)).filter(loc => loc.onBoard(size));
    }

    /**
     * Get all of out neighbours up to the edge of the board.
     */
    private neighboursToEdge(direction: GridLocation, size: GridSize): GridLocation[] {
        let ret = new Array<GridLocation>();
        let curloc = this.withMove(direction);
        while (curloc.onBoard(size)) {
            ret.push(curloc);
            curloc = curloc.withMove(direction);
        }
        return ret;
    }

    public onBoard(size: GridSize) {
        return this.col >= 0 && this.row >= 0 && this.col < size.width && this.row < size.height;
    }

    equals(loc: GridLocation) {
        return this.row === loc.row && this.col === loc.col;
    }
}

export default GridLocation;