import {DifficultyLevel, GameOptions, InitialBoardState} from "./types";
import GridLocation from "./logic/gridLocation";
import {PersistantBoolean, PersistantNumber, PersistantString} from "./persistantValue";

export class Directions {
    public static readonly Up = new GridLocation(-1, 0);
    public static readonly Down = new GridLocation(1, 0);
    public static readonly Left = new GridLocation(0, -1);
    public static readonly Right = new GridLocation(0, 1);
    public static readonly allDirections = [Directions.Up, Directions.Down, Directions.Left, Directions.Right];
}

class Constants {
    public static readonly minRedProportion = 0.18;
    public static readonly maxRedProportion = 0.35;
    public static readonly defaultGameOptions: GameOptions = {
        size: {height: 6, width: 6,},
        eliminateRedsFirst: true,
        sameSolutionAsPreviousGame: false,
        difficulty: "hard",
        displayOptions: {
            includeSelfInSeenBluesCount: false,
        }
    }

    static readonly difficultyPV = new PersistantString('difficulty', Constants.defaultGameOptions.difficulty);
    static readonly squareBoardPV = new PersistantBoolean('squareBoard', true);
    static readonly includeSelfInSeenBluesCountPV = new PersistantBoolean('includeSelfInSeenBluesCount', false);
    static readonly sizePV = new PersistantNumber('size', Constants.defaultGameOptions.size.height);
    static readonly heightPV = new PersistantNumber('height', Constants.defaultGameOptions.size.height);
    static readonly widthPV = new PersistantNumber('width', Constants.defaultGameOptions.size.width);

    public static simpleFiveByFivePuzzle: InitialBoardState = {
        size: {width: 5, height: 5},
        constraints: [
            {
                loc: new GridLocation(0, 1),
                color: "blue",
                visibleNeighbours: 3
            },
            {
                loc: new GridLocation(1, 4),
                color: "blue",
                visibleNeighbours: 3
            },
            {
                loc: new GridLocation(2, 0),
                color: "blue",
                visibleNeighbours: 2
            },
            {
                loc: new GridLocation(3, 3),
                color: "blue",
                visibleNeighbours: 2
            },
            {
                loc: new GridLocation(3, 2),
                color: "blue",
                visibleNeighbours: 1
            },
            {
                loc: new GridLocation(4, 3),
                color: "blue",
                visibleNeighbours: 1
            },
            {
                loc: new GridLocation(4, 0),
                color: "blue",
                visibleNeighbours: 1
            },
            {
                loc: new GridLocation(1, 1),
                color: "red",
            },
            {
                loc: new GridLocation(2, 1),
                color: "red",
            },
        ],
    };

    /**
     * This indicates how we treat difficulty settings. For the given difficulty level, the indicated proportion of
     * superfluous constraints (blue numbers) will be reintroduced to the puzzle dispayed to the user.
     */
    static constraintReturnsForDifficulty: Record<DifficultyLevel, number> = {
        easy: 0.20,
        medium: 0.1,
        hard: 0,
    }
    static minSelectableGridSize: number = 4;
    static maxSelectableGridSize: number = 15;

}


export default Constants;
