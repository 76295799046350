import {GridSize, InitialBoardState, SatisfactionState, TileColor, TileState} from "../types";
import GridLocation from "./gridLocation";
import {countAdjacentBlues} from "../utils";

interface GameMove {
    loc: GridLocation,
    oldColor: TileColor,
    newColor: TileColor,
}

class Game {
    public size: GridSize;
    public tileCount: number;
    public tileStates: Array<TileState>[];
    private constraintsInRow: Map<number, TileState[]>;
    private constraintsInCol: Map<number, TileState[]>;
    private markedTileCount: number;
    private properlySatisfiedConstraints: number;
    private totalConstraints: number;
    private moveStack: GameMove[];

    constructor(private readonly initialState: InitialBoardState) {
        this.size = initialState.size;
        this.tileCount = this.size.height * this.size.width;
        this.markedTileCount = 0;
        this.properlySatisfiedConstraints = 0;
        this.tileStates = new Array<Array<TileState>>(this.size.height);
        this.constraintsInRow = new Map<number, TileState[]>();
        this.constraintsInCol = new Map<number, TileState[]>();
        this.moveStack = new Array<GameMove>();

        // Initialize our data for the tiles on the board.
        for (let i = 0; i < this.size.height; i += 1) {
            this.tileStates[i] = new Array<TileState>(this.size.width);
            for (let j = 0; j < this.size.width; j += 1) {
                this.tileStates[i][j] = {
                    location: new GridLocation(i, j),
                    currentColor: 'unknown',
                    // This will be reset immediately below for tiles in the problem definition.
                    fixedColor: false,
                }
            }
        }

        initialState.constraints.forEach((constraint) => {
            if (constraint.visibleNeighbours === undefined && constraint.color === undefined) {
                throw new Error("Constraint in initial board state doesn't provide a constraint.\n" + constraint);
            }
            if (!this.onBoard(constraint.loc)) {
                throw new Error(`Constraint off the board: ${constraint}`);
            }
            this.markedTileCount += 1;
            const tile = this.getTile(constraint.loc);
            tile.currentColor = constraint.color;
            tile.fixedColor = true;
            tile.requiredBlueNeighbours = constraint.visibleNeighbours;

            // ConstraintState set here for later updates.
            if (constraint.visibleNeighbours !== undefined) {
                tile.constraintState = {
                    // This might be wrong, but that's ok, we're about to evaluate all the constraints in the opening
                    // position.
                    satisfactionState: SatisfactionState.TooFew,
                };
                this.initializeConstraintLocationArrays(tile);

            }
        });

        this.totalConstraints = 0;
        this.constraintsInCol.forEach((value, key) => {
            this.totalConstraints += value.length;
            value.forEach(tile => this.reevaluateConstraint(tile));
        });

    }

    public getTile(loc: GridLocation): TileState {
        return this.tileStates[loc.row][loc.col];
    }

    public toggleCellColor(loc: GridLocation) {
        const ts = this.getTile(loc);
        if (ts.fixedColor) {
            console.error(`You can't change color of ${loc.row}/${loc.col}`);
            return;
        }
        try {
            if (ts.currentColor === "blue") this.setColor(loc, "red");
            else if (ts.currentColor === "red") this.setColor(loc, "unknown");
            else if (ts.currentColor === "unknown") this.setColor(loc, "blue");
        } catch (e) {
            console.error(`Couldn't change color of ${loc}: ${e}`);
        }
    }

    public setColor(loc: GridLocation, newColor: TileColor, undoable: boolean = true) {
        const ts = this.getTile(loc);
        if (ts.fixedColor) {
            throw new Error(`You can't change color of ${ts}`);
        }
        const oldColor = ts.currentColor;
        const hadColor = (oldColor !== 'unknown');
        const hasColor = (newColor !== 'unknown');
        if (hadColor && !hasColor) this.markedTileCount -= 1;
        if (!hadColor && hasColor) this.markedTileCount += 1;
        // console.log(`assigning ${loc} ${newColor}`);
        ts.currentColor = newColor;

        if (undoable) this.moveStack.push({loc, oldColor, newColor});

        // Reevaluate the constraints that might be relevant to this position.
        if (this.constraintsInRow.has(loc.row))
            this.constraintsInRow.get(loc.row)!.forEach(tile => {
                this.reevaluateConstraint(tile);
            });
        if (this.constraintsInCol.has(loc.col))
            this.constraintsInCol.get(loc.col)!.forEach(tile => {
                this.reevaluateConstraint(tile);
            });
    }

    public undo() {
        if (this.moveStack.length === 0) {
            console.error(`Nothing to undo.`);
            return;
        }
        const move = this.moveStack.pop()!;
        this.setColor(move.loc, move.oldColor, false);
    }

    public isCompleted() {
        return this.properlySatisfiedConstraints === this.totalConstraints
            && this.markedTileCount === this.tileCount;
    }

    getColor: (loc: GridLocation) => TileColor = (loc: GridLocation) => {
        if (!this.onBoard(loc)) return 'off board';
        const tile = this.getTile(loc);
        return tile.currentColor;
    }

    private onBoard(loc: GridLocation) {
        return (loc.col >= 0 && loc.col < this.size.width) && (loc.row >= 0 && loc.row < this.size.height);
    }

    /**
     * Initialize our constraint mappings for easy access to constraints in each row  or column.
     * @param tile Our tile state that corresponds to  a location with a constraint in it.
     * @param constraintArray One of our internal mappings from either row or column number to relevant constraints.
     * @param location The row or column in the constraintArray that we're putting tile into.
     */
    private initializeConstraintLocationArrays(tile: TileState,
                                               constraintArray: Map<number, TileState[]> | undefined = undefined,
                                               location: number | undefined = undefined) {
        if (constraintArray && location !== undefined) {
            // There's a way to do this slightly more efficiently but who cares?
            if (!constraintArray.has(location)) constraintArray.set(location, new Array<TileState>());
            constraintArray.get(location)!.push(tile);
        } else {
            this.initializeConstraintLocationArrays(tile, this.constraintsInRow, tile.location.row);
            this.initializeConstraintLocationArrays(tile, this.constraintsInCol, tile.location.col);
        }
    }

    private evaluateConstraint(tile: TileState): SatisfactionState {
        if (!tile.requiredBlueNeighbours) {
            throw new Error(`Tile shouldn't have it's constraint evaluated if it has no constraint. ${tile}`);
        }

        const result = countAdjacentBlues(tile.location, this.getColor);
        const required = tile.requiredBlueNeighbours;

        if (result.blueCount === required) {
            return result.allProperlyTerminated ? SatisfactionState.Satisfied : SatisfactionState.RightNumberButUnterminated;
        } else {
            if (result.blueCount < required) return SatisfactionState.TooFew;
            if (result.blueCount > required) return SatisfactionState.TooMany;
        }


        console.log(`result  ${result.blueCount}/${required} ${result.allProperlyTerminated} ${tile.location}`)
        this.logRB();
        throw new Error(`Code shouldn't get here.`);
    }

    // private countAdjacentBlues(location: GridLocation): BlueCountResult {
    //     return countAdjacentBlues(location, this.getColor)
    // }

    private reevaluateConstraint(tile: TileState) {
        if (!tile.constraintState) throw new Error(`Don't evaluate constraints on constraint-free tiles. ${tile}`);
        const oldValue = tile.constraintState.satisfactionState;
        const result = this.evaluateConstraint(tile);

        // Update our counter.
        if (oldValue === SatisfactionState.Satisfied && result !== SatisfactionState.Satisfied) {
            this.properlySatisfiedConstraints -= 1;
        }
        if (oldValue !== SatisfactionState.Satisfied && result === SatisfactionState.Satisfied) {
            this.properlySatisfiedConstraints += 1;
        }
        tile.constraintState.satisfactionState = result;
    }

    private logRB() {
        const board = this.tileStates.map(row => row.map(ts => ts.currentColor[0]).join('')).join('\n');
        console.log(board);
    }

}

export default Game;